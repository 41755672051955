<template>
  <div style="height: 100%">
    <v-container style="height: 70%" align-center justify-center>
      <v-row>
        <v-col cols="3" />
        <v-col cols="6"
          ><strong style="color: #ff0000">{{
            $t("reminders.title")
          }}</strong></v-col
        >
        <v-col cols="3" />
      </v-row>

      <v-row>
        <v-col cols="3" />
        <v-col cols="6">{{ $t("reminders.intro") }}</v-col>
        <v-col cols="3" />
      </v-row>
      <v-row style="height: 30%">
        <v-col cols="3" />
        <v-col cols="3">
          <v-card style="height: 100%">
            <v-card-title
              ><v-icon style="color: #ff0000"
                >mdi-head-cog</v-icon
              ></v-card-title
            >
            <v-card-text>{{ $t("reminders.forget") }}</v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card style="height: 100%">
            <v-card-title
              ><v-icon style="color: #ff0000">mdi-car-key</v-icon></v-card-title
            >
            <v-card-text>{{ $t("reminders.lock") }}</v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3" />
      </v-row>
      <v-row style="height: 30%">
        <v-col cols="3" />
        <v-col cols="3">
          <v-card style="height: 100%">
            <v-card-title
              ><v-icon style="color: #ff0000"
                >mdi-key-chain-variant</v-icon
              ></v-card-title
            >
            <v-card-text>{{ $t("reminders.otherkeys") }}</v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card style="height: 100%">
            <v-card-title
              ><v-icon style="color: #ff0000"
                >mdi-car-door</v-icon
              ></v-card-title
            >
            <v-card-text>{{ $t("reminders.window") }}</v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3" />
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "Reminders",
};
</script>
